<template>
  <v-layout fill-height column class="grey lighten-5">
    <v-container fluid>
      <v-row align="center" justify="center" align-content="center" class="py-3">
        <v-col>
          <h1>Team</h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="flex-grow-0">
          <v-btn
              :to="{name: 'team.create', params: {}}"
              color="green"
              depressed
              dark>Add new
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <Pagination
        :items="items"
        :loading="loading"
        @update="onUpdatePaging"
        :pagination="pagination"
    >
      <template v-slot:default="{items}">
        <v-container fluid class="fill-height column align-start">
          <v-row>
            <v-col
                v-for="item in items"
                :key="item.uuid"
                cols="12"
                sm="6"
                md="4"
                lg="3"
            >
              <MemberCard
                  :key="item.uuid"
                  :loading="instanceLoading === item.uuid"
                  :item="item"
              ></MemberCard>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </Pagination>
  </v-layout>
</template>

<script>
import {makeCrudView} from "../../store/crud";
import MemberCard from "../../components/common/MemberCard";
import Pagination from "../../components/common/Pagination";
import {mapFields} from "vuex-map-fields";

export default {
  name: "TeamList",
  components: {Pagination, MemberCard},
  extends: makeCrudView('team'),
  computed: {
    ...mapFields('team', {
      'page': 'query.page',
      'limit': 'query.limit'
    }),
  },
  methods: {
    onUpdatePaging({page, limit}) {
      this.page = page;
      this.fetch();
    }
  },
}
</script>